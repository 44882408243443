<template>
  <UiPopup
    :model-value="modelValue"
    title="Schedule message"
    :loading="loading"
    hidden
    size="big"
    @update:model-value="emits('update:modelValue', false)"
  >
    <UiInputTextarea
      v-model="form.body"
      name="body"
      :limit="false"
      text-area-class="!min-h-[68px] max-h-[148px]"
      label="Message text"
      placeholder="Placeholder"
      class="mb-5 bg-white"
      :show-save-button="false"
      :error="useGetFieldErrors(v$, ['body'])"
    />
    <div class="flex w-1/2 flex-col gap-3 text-left">
      <h5 class="text-subhead-3 text-black-80">Date and time</h5>
      <div class="flex flex-row items-center gap-3">
        <UiIcon name="calendar" class="text-black-70" />
        <UiInputDatePicker
          v-model="form.date"
          name="date"
          :show-icon="false"
          :min-date="new Date()"
          :start-with-placeholder="false"
          date-format="MMM dd, yyyy"
          :error="useGetFieldErrors(v$, ['date'])"
        />
      </div>
      <div class="flex items-center gap-3">
        <UiIcon name="clock" class="min-w-6 text-black-70" />
        <UiInputSelect
          v-model="form.time"
          :items="timeOptions"
          :removable="false"
          name="time"
          :hide-arrow="true"
          :error="useGetFieldErrors(v$, ['time'])"
        />
      </div>
    </div>
    <div class="mt-3 flex justify-end gap-4">
      <UiButtonBase id="cancel" type="secondary" class="!w-auto" @click="emits('update:modelValue', false)">
        Cancel
      </UiButtonBase>
      <UiButtonBase id="save" class="!w-auto" :disabled="loading" @click="submit">
        {{ editMessage ? 'Save changes' : 'Save' }}</UiButtonBase
      >
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import { differenceInMilliseconds, isBefore } from 'date-fns'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import type { WhatsappMessage } from '@/types'

const emits = defineEmits(['update:modelValue'])

type Props = {
  modelValue: boolean
  whatsappChatId?: string
  editMessage?: WhatsappMessage
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  whatsappChatId: undefined,
  editMessage: undefined,
})

const loading = ref(false)

const form = ref({
  body: props.editMessage ? props.editMessage.body : '',
  date: new Date(),
  time: null,
})

onMounted(() => {
  if (props.editMessage) {
    const date = new Date(props.editMessage.sendAt)
    form.value.date = date
    form.value.time = { hours: date.getHours(), minutes: date.getMinutes() }
  }
})

const timeOptions = computed(() => {
  const result = []
  for (let hours = 0; hours < 24; hours++) {
    for (let minutes = 0; minutes < 60; minutes = minutes + 15) {
      result.push({
        text: `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`,
        value: { hours, minutes },
        disabled: isBefore(new Date(new Date(form.value.date).setHours(hours, minutes)), new Date()),
      })
    }
  }
  return result
})

const rules = computed(() => ({
  body: { required: helpers.withMessage('The body is required', required) },
  date: { required: helpers.withMessage('The date is required', required) },
  time: { required: helpers.withMessage('The time is required', required) },
}))

const v$ = useVuelidate(rules, form)

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (!isValid) return
  loading.value = true
  const date = new Date(form.value.date)
  const currentDate = new Date().setSeconds(0)
  const fullDate = new Date(date.setHours(form.value.time.hours, form.value.time.minutes, 0))
  const dateToMilliseconds = differenceInMilliseconds(fullDate, currentDate)
  if (props.editMessage) {
    const payload = {
      taskId: props.editMessage.id,
      body: form.value.body,
      sendIn: dateToMilliseconds,
    }
    await triggerWAEditScheduleMessage(payload)
  } else {
    const payload = {
      whatsAppChatId: props.whatsappChatId,
      body: form.value.body,
      sendIn: dateToMilliseconds,
    }
    await triggerWASendScheduleMessage(payload)
  }

  emits('update:modelValue', false)
  loading.value = false
}
</script>

<style scoped></style>
